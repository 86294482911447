import alertConstants from './constants';

// options = {inline: boolean}
const success = (message, options) => ({ type: alertConstants.SUCCESS, message, options });
const error = (message, options) => ({ type: alertConstants.ERROR, message, options });
const clear = () => ({ type: alertConstants.CLEAR });

const alertActions = {
  success,
  error,
  clear,
};
export default alertActions;
