const dev = {
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'talk-sync-files-dev-downloadsbucket-1v0wj2px7xynb',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://dev.api.talksyncapp.com',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_bRwzRvFkv',
    APP_CLIENT_ID: '53c9kal5taaras5c3er4p794n3',
    IDENTITY_POOL_ID: 'us-west-2:ab6f9019-ea06-4c77-87bb-dacad8c0e168',
  },
  contact: {
    PATH: '/hello',
  },
  google: {
    ANALYTICS_CODE: 'UA-148897739-2',
  },
  stripe: {
    PUBLISHABLE_KEY: 'pk_test_7MRPtnJ9irIOYkw7cSUq9lch00AvYtTALX',
  },
};

const prod = {
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'talk-sync-files-prod-downloadsbucket-joo5qfaigiqy',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://api.talksyncapp.com',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_UmVD9cUlJ',
    APP_CLIENT_ID: '6t55fosu6ph4hc02l8rfk99ev1',
    IDENTITY_POOL_ID: 'us-west-2:d438eb3a-e2c2-4245-a19a-7f339fb1eeb0',
  },
  contact: {
    PATH: '/hello',
  },
  google: {
    ANALYTICS_CODE: 'UA-148897739-2',
  },
  stripe: {
    PUBLISHABLE_KEY: 'pk_live_itDpJTb3TGwhKFFYiKBAj7vy00Xe58RVoa',
  },
};

const site = {
  SITE_TITLE: 'Talk Sync',
  ALERT_FADEOUT: 11000,
  IMAGE_FORMATS: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'],
  AUDIO_FORMATS: ['audio/mp3', 'audio/mp4'],
  UNIVERSAL_LINK_PREFIX: 'https://talksyncapp.com/c/',
  APP_LINK_PREFIX: 'talksync://c/',
  IOS_PACKAGE: 'com.western-sound.app.talksync',
  ANDROID_PACKAGE: 'com.westernsound.app.talksync',
  IOS_APPSTORE_LINK: 'https://apps.apple.com/us/app/talk-sync/id1510146898?ls=1',
  ANDROID_APPSTORE_LINK: 'https://play.google.com/store/apps/details?id=com.westernsound.app.talksync',
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
// const config = prod;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
  site,
};
