import linkcodeConstants from './constants';

const initialState = {
  isCreating: false,
  isListing: false,
  isGetting: false,
  isTexting: false,
  isUpdating: false,
  isDeleteing: false,
  items: null,
  item: null,
  error: null,
};

const linkcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case linkcodeConstants.CREATE_REQUEST:
      return {
        ...state,
        isCreating: true,
        error: null,
      };
    case linkcodeConstants.CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        items: state.items ? [...state.items, action.result] : [action.result],
        item: action.result,
      };
    case linkcodeConstants.CREATE_FAILURE:
      return {
        ...state,
        isCreating: false,
        item: null,
        error: action.error,
      };
    case linkcodeConstants.LIST_REQUEST:
      return {
        ...state,
        isListing: true,
        error: null,
      };
    case linkcodeConstants.LIST_SUCCESS:
      return {
        ...state,
        isListing: false,
        items: action.result,
      };
    case linkcodeConstants.LIST_FAILURE:
      return {
        ...state,
        isListing: false,
        items: null,
        error: action.error,
      };
    case linkcodeConstants.GET_REQUEST:
      return {
        ...state,
        isGetting: true,
        error: null,
      };
    case linkcodeConstants.GET_SUCCESS:
      return {
        ...state,
        isGetting: false,
        item: action.result,
      };
    case linkcodeConstants.GET_FAILURE:
      return {
        ...state,
        isGetting: false,
        item: null,
        error: action.error,
      };
    case linkcodeConstants.CHECK_REQUEST:
      return {
        ...state,
        isCheckingLinkcode: true,
        error: null,
      };
    case linkcodeConstants.CHECK_SUCCESS:
      return {
        ...state,
        isCheckingLinkcode: false,
        isLinkcodeValid: true,
      };
    case linkcodeConstants.CHECK_FAILURE:
      return {
        ...state,
        isCheckingLinkcode: false,
        isLinkcodeValid: false,
      };
    case linkcodeConstants.TEXT_REQUEST:
      return {
        ...state,
        isTexting: true,
        error: null,
      };
    case linkcodeConstants.TEXT_SUCCESS:
      return {
        ...state,
        isTexting: false,
        item: action.result,
      };
    case linkcodeConstants.TEXT_FAILURE:
      return {
        ...state,
        isTexting: false,
        item: null,
        error: action.error,
      };
    case linkcodeConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case linkcodeConstants.UPDATE_SUCCESS:
      const updatedItems = state.items
        ? state.items.map((item) =>
            item.linkcodeId === action.result.linkcodeId ? { ...item, ...action.result } : item
          )
        : state.items;
      return {
        ...state,
        isUpdating: false,
        items: updatedItems,
        item: { ...state.item, ...action.result },
      };
    case linkcodeConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case linkcodeConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleteing: true,
        error: null,
      };
    case linkcodeConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleteing: false,
        items: state.items.filter((item) => item.linkcodeId !== action.result.linkcodeId),
        item: null,
      };
    case linkcodeConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleteing: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default linkcodeReducer;
