const recordingConstants = {
  LIST_REQUEST: 'RECORDINGS_LIST_REQUEST',
  LIST_SUCCESS: 'RECORDINGS_LIST_SUCCESS',
  LIST_FAILURE: 'RECORDINGS_LIST_FAILURE',

  GET_REQUEST: 'RECORDINGS_GET_REQUEST',
  GET_SUCCESS: 'RECORDINGS_GET_SUCCESS',
  GET_FAILURE: 'RECORDINGS_GET_FAILURE',

  DOWNLOAD_REQUEST: 'RECORDINGS_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'RECORDINGS_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'RECORDINGS_DOWNLOAD_FAILURE',

  UPDATE_REQUEST: 'RECORDINGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RECORDINGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RECORDINGS_UPDATE_FAILURE',

  DELETE_REQUEST: 'RECORDINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'RECORDINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'RECORDINGS_DELETE_FAILURE',
};

export default recordingConstants;
