import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

import userActions from './api/user/actions';

import Routes from './routing/Routes';
import Header from './components/page/Header';
import Footer from './components/page/Footer';
import Alert from './components/page/Alert';
import ErrorBoundary from './containers/common/ErrorBoundary';
import { AppProvider } from './helpers/AppContext';

import { DateTime } from 'luxon';

import './App.scss';
import { AlarmAlert } from './components/page/AlarmAlert';

const App = () => {
  const authentication = useSelector((state) => state.authentication);
  const { isSilentlySigningIn, isSignedIn } = authentication;
  const userData = useSelector((state) => state.user);
  const { user } = userData;

  const dispatch = useDispatch();

  // update user timezone when they log in
  useEffect(() => {
    if (isSignedIn && user) {
      const timezone = new DateTime.local().zone.name;
      if (!user.preferences || user.preferences.timezone !== timezone) {
        dispatch(userActions.updatePreferences(user, { timezone }));
      }
    }
  }, [dispatch, isSignedIn, user]);

  useEffect(() => {
    dispatch(userActions.currentSession());
  }, [dispatch]);

  return (
    !isSilentlySigningIn && (
      <AppProvider>
        <div className="App d-flex flex-column h-100">
          <Header />
          <Container>
            <ErrorBoundary>
              <AlarmAlert dismissable={true} />
              <Alert />
              <Routes />
            </ErrorBoundary>
          </Container>
          <Footer />
        </div>
      </AppProvider>
    )
  );
};

export default App;
