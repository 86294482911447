import React from 'react';
import { logError } from '../../helpers/errorLib';
import './ErrorBoundary.scss';

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <div className="ErrorBoundary">
        <div className="row">
          <div className="col-md-12">
            <h3>Sorry, there was a problem loading this page.</h3>
          </div>
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}
