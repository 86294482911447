import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function AuthenticatedRoute({ children, ...rest }) {
  const authentication = useSelector((state) => state.authentication);
  const { isSignedIn } = authentication;
  const userData = useSelector((state) => state.user);
  const { user } = userData;

  const { pathname, search } = useLocation();
  if (!user || !isSignedIn) {
    return (
      <Route {...rest}>
        <Redirect to={`/signin?redirect=${pathname}${search}`} />
      </Route>
    );
  } else if (user.invitations && user.invitations.length > 0 && pathname !== '/settings/accept-invitations') {
    return (
      <Route {...rest}>
        <Redirect to="/settings/accept-invitations" />
      </Route>
    );
  } else if (
    user.userStatus === 'none' &&
    (!user.invitations || user.invitations.length === 0) &&
    pathname !== '/choose-membership' &&
    pathname !== '/settings/membership/enterprise'
  ) {
    return (
      <Route {...rest}>
        <Redirect to="/choose-membership" />
      </Route>
    );
  } else if (user.userStatus === 'lapsed' && pathname !== '/settings/billing' && pathname !== '/settings/membership') {
    return (
      <Route {...rest}>
        <Redirect to="/settings/billing" />
      </Route>
    );
  } else {
    return <Route {...rest}>{children}</Route>;
  }
}
