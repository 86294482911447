import { push } from 'connected-react-router';
import userConstants from './constants';
import userService from './service';
import alertActions from '../alert/actions';

const errorMessages = {
  LimitExceededException: "Sorry, you've sent too many codes! Try again later, or use that last one that you received.",
  UserNotFoundException: "Sorry, we couldn't find you. Check your spelling and try again.",
  CodeMismatchException: 'Sorry, that code is invalid. Please try again.',
  NotAuthorizedException: 'Sorry, that username or password is incorrect. Please try again.',
  AliasExistsException: 'Sorry, An account with that email address already exists.',
  UsernameExistsException: 'Sorry, An account with that email address already exists.',
};

const errorMessage = (error) => {
  if (error.response && error.response.data && error.response.data.error)
    return errorMessages[error.response.data.error.code] || error.response.data.error.message;
  return errorMessages[error.code] || `${error.message} [${error.code}]`;
};

const signup = (email, password) => {
  const request = () => ({ type: userConstants.SIGNUP_REQUEST });
  const success = (result) => ({ type: userConstants.SIGNUP_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.SIGNUP_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    userService.signup(email, password).then(
      (result) => {
        dispatch(success(result));
        dispatch(push('/signup/confirmation'));
        dispatch(alertActions.success("You've signed up!"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const confirmSignup = (email, code) => {
  const request = () => ({ type: userConstants.SIGNUP_CONFIRMATION_REQUEST });
  const success = (result) => ({ type: userConstants.SIGNUP_CONFIRMATION_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.SIGNUP_CONFIRMATION_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.confirmSignup(email, code).then(
      (result) => {
        dispatch(success());
        dispatch(push('/signin'));
        dispatch(alertActions.success("You've been confirmed! Please sign in to continue."));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const resendSignupCode = (email) => {
  const request = () => ({ type: userConstants.SIGNUP_RESEND_CODE_REQUEST });
  const success = () => ({ type: userConstants.SIGNUP_RESEND_CODE_SUCCESS });
  const failure = (error) => ({ type: userConstants.SIGNUP_RESEND_CODE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.resendSignupCode(email).then(
      () => {
        dispatch(success());
        dispatch(
          alertActions.success(
            `We've resent your confirmation code. Check ${email} and paste the code in the form below.`
          )
        );
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const signin = (username, password) => {
  const request = () => ({ type: userConstants.SIGNIN_REQUEST });
  const success = (result) => ({ type: userConstants.SIGNIN_SUCCESS, result });
  const challenge = (result) => ({ type: userConstants.SIGNIN_CHALLENGE, result });
  const failure = (error) => ({ type: userConstants.SIGNIN_FAILURE, error });

  const getSuccess = (result) => ({ type: userConstants.GET_SUCCESS, result });

  return (dispatch) => {
    dispatch(request());
    // console.log('signing in');
    userService.signin(username, password).then(
      async (result) => {
        // console.log('signin success', result);
        const authUser = result.attributes;
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const message = 'Welcome! You need to update your password.';
          dispatch(challenge(authUser));
          dispatch(alertActions.success(message));
          dispatch(push('/signin/new-password'));
        } else {
          const user = await userService.get();
          dispatch(success(authUser));
          dispatch(getSuccess(user));
          // console.log('user', user);
          if (user.userStatus === 'none') {
            const message = 'Welcome to Talk Sync!';
            if (user.invitations && user.invitations.length > 0) {
              dispatch(push('accept-invitations'));
            } else {
              dispatch(push('choose-membership'));
            }
            dispatch(alertActions.success(message));
          } else {
            const message = "Welcome back! You've been signed in.";
            dispatch(alertActions.success(message));
          }
        }
      },
      (error) => {
        // console.log('sign in error', error);
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const signout = () => {
  const request = () => ({ type: userConstants.SIGNOUT_REQUEST });
  const success = (result) => ({ type: userConstants.SIGNOUT_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.SIGNOUT_FAILURE, error });

  // console.log('sgnout');
  return (dispatch) => {
    dispatch(request());

    userService.signout().then(
      (result) => {
        // console.log('signout success');
        const message = "You've been signed out. See ya later!";
        dispatch(success(result));
        dispatch(push('/'));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const currentSession = () => {
  const request = () => ({ type: userConstants.CURRENT_SESSION_REQUEST });
  const success = (result) => ({ type: userConstants.CURRENT_SESSION_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.CURRENT_SESSION_FAILURE, error });

  const getSuccess = (result) => ({ type: userConstants.GET_SUCCESS, result });

  return (dispatch) => {
    dispatch(request());
    userService.currentSession().then(
      async (result) => {
        const authUser = result.getIdToken().payload;
        // console.log('currentSession', authUser);
        try {
          const user = await userService.get();
          dispatch(success(authUser));
          dispatch(getSuccess(user));
        } catch (e) {
          // this only happens when a user is deleted in aws but still logged in
          dispatch(signout());
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const forgotPasswordCode = (username) => {
  const request = () => ({ type: userConstants.FORGOT_PASSWORD_CODE_REQUEST });
  const success = (result) => ({ type: userConstants.FORGOT_PASSWORD_CODE_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.FORGOT_PASSWORD_CODE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    userService.forgotPasswordCode(username).then(
      (result) => {
        const stubbedUser = {
          // mocks the user returned from AWS
          user: {
            username: username,
            blankedEmail: result.Destination,
          },
        };
        const message = 'Your code has been sent.';
        dispatch(success(stubbedUser));
        dispatch(push('/signin/forgot/reset'));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const resendForgotPasswordCode = (username) => {
  const request = () => ({ type: userConstants.FORGOT_PASSWORD_RESEND_CODE_REQUEST });
  const success = (result) => ({ type: userConstants.FORGOT_PASSWORD_RESEND_CODE_REQUEST, result });
  const failure = (error) => ({ type: userConstants.FORGOT_PASSWORD_RESEND_CODE_REQUEST, error });

  return (dispatch) => {
    dispatch(request());

    userService.resendForgotPasswordCode(username).then(
      (result) => {
        const stubbedUser = {
          // mocks the user returned from AWS
          user: {
            username: username,
            blankedEmail: result.Destination,
          },
        };
        const message = `We've resent your confirmation code. Check ${username} and paste the code in the form below.`;
        dispatch(success(stubbedUser));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const forgotPasswordUpdate = (username, code, password) => {
  const request = () => ({ type: userConstants.FORGOT_PASSWORD_UPDATE_REQUEST });
  const success = (result) => ({ type: userConstants.FORGOT_PASSWORD_UPDATE_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.FORGOT_PASSWORD_UPDATE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    userService.forgotPasswordUpdate(username, code, password).then(
      (result) => {
        const message = 'Your password has been reset.';
        dispatch(success(result));
        dispatch(push('/signin'));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const get = () => {
  const request = () => ({ type: userConstants.GET_REQUEST });
  const success = (result) => ({ type: userConstants.GET_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.GET_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.get().then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error))
    );
  };
};

const update = (user, showAlert = true) => {
  const request = () => ({ type: userConstants.UPDATE_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    userService.update(user).then(
      (result) => {
        if (showAlert) {
          const message = 'Your settings have been updated.';
          dispatch(alertActions.success(message));
        }
        dispatch(success(result));
      },
      (error) => {
        dispatch(alertActions.error(errorMessage(error)));
        dispatch(failure(error));
      }
    );
  };
};

const updatePreferences = (user, preferences) => {
  const request = () => ({ type: userConstants.UPDATE_PREFERENCE_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_PREFERENCE_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_PREFERENCE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    const updatedPreferences = { ...user.preferences, ...preferences };
    const updatedUser = { ...user, ...{ preferences: updatedPreferences } };
    // console.log({ updatedUser });
    userService.update(updatedUser).then(
      (result) => {
        // console.log({ result });
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const updateEmail = (user) => {
  const request = () => ({ type: userConstants.UPDATE_EMAIL_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_EMAIL_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_EMAIL_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    // console.log('updateEmail success', user);

    userService.updateEmail(user.email).then(
      (result) => {
        const message = 'Your email has been updated.';
        userService.update(user);
        dispatch(alertActions.success(message));
        dispatch(push('/settings/email/confirmation'));
        dispatch(success(result));
      },
      (error) => {
        dispatch(alertActions.error(errorMessage(error)));
        dispatch(failure(error.toString()));
      }
    );
  };
};

const listSublicensees = (conditions = {}) => {
  const request = () => ({ type: userConstants.LIST_SUBLICENSEES_REQUEST });
  const success = (result) => ({ type: userConstants.LIST_SUBLICENSEES_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.LIST_SUBLICENSEES_FAILURE, error });

  return async (dispatch) => {
    dispatch(request());

    try {
      if (conditions && conditions.constructor === Object && Object.keys(conditions).length > 0) {
        const results = await userService.listSublicensees(conditions);
        const message = "User found! Click 'Add user' to send them an invitation.";
        dispatch(alertActions.success(message, { inline: true }));
        dispatch(success(results));
      } else {
        const results = null;
        dispatch(alertActions.clear());
        dispatch(success(results));
      }
    } catch (error) {
      dispatch(alertActions.error(errorMessage(error), { inline: true }));
      dispatch(failure(error));
    }
  };
};

const updateSublicensees = (user, sublicensees) => {
  const request = () => ({ type: userConstants.UPDATE_SUBLICENSEES_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_SUBLICENSEES_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_SUBLICENSEES_FAILURE, error });

  const oldSublicensees = user.sublicensees || [];

  return (dispatch) => {
    dispatch(request());
    const updatedUser = { ...user, ...{ sublicensees } };
    userService.updateSublicensees(updatedUser).then(
      (result) => {
        const message =
          result.sublicensees.length > oldSublicensees.length
            ? "The user has been added. We'll update here when they've accepted the invitation."
            : 'The user has been removed from your organization.';
        dispatch(alertActions.success(message));
        dispatch(success(result));
      },
      (error) => {
        dispatch(alertActions.error(errorMessage(error)));
        dispatch(failure(error));
      }
    );
  };
};

const updateInvitation = (userId, accept) => {
  const request = () => ({ type: userConstants.UPDATE_INVITATION_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_INVITATION_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_INVITATION_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.updateInvitation(userId, accept).then(
      (result) => {
        const message = accept
          ? 'The invitation has been accepted and you have joined their organization.'
          : 'The invitation has been declined.';
        const nextPage = accept ? '/settings' : '/recordings';
        dispatch(alertActions.success(message));
        dispatch(success(result));
        dispatch(push(nextPage));
      },
      (error) => {
        dispatch(alertActions.error(errorMessage(error)));
        dispatch(failure(error));
      }
    );
  };
};

const resendUpdateEmailCode = (email) => {
  const request = () => ({ type: userConstants.UPDATE_EMAIL_RESEND_CODE_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_EMAIL_RESEND_CODE_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_EMAIL_RESEND_CODE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.resendUpdateEmailCode().then(
      (result) => {
        const message = `We've resent your confirmation code. Check ${email} and paste the code in the form below.`;
        dispatch(success(result));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const confirmUpdateEmail = (code) => {
  const request = () => ({ type: userConstants.UPDATE_EMAIL_CONFIRMATION_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_EMAIL_CONFIRMATION_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_EMAIL_CONFIRMATION_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.confirmUpdateEmail(code).then(
      (result) => {
        dispatch(success());
        dispatch(push('/settings'));
        dispatch(alertActions.success('Your email has been updated.'));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(errorMessage(error)));
      }
    );
  };
};

const challengePassword = (cognitoUser, newPassword) => {
  const request = () => ({ type: userConstants.UPDATE_PASSWORD_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_PASSWORD_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_PASSWORD_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    userService.challengePassword(cognitoUser, newPassword).then(
      (result) => {
        const message = 'Your password has been updated. Log in to continue';
        dispatch(alertActions.success(message));
        dispatch(push('/signin'));
        dispatch(success(result));
      },
      (error) => {
        const message =
          error.code === 'NotAuthorizedException'
            ? 'Sorry, your password is incorrect. Please try again.'
            : errorMessage(error);
        dispatch(alertActions.error(message));
        dispatch(failure(error.toString()));
      }
    );
  };
};

const updatePassword = (oldPassword, newPassword) => {
  const request = () => ({ type: userConstants.UPDATE_PASSWORD_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_PASSWORD_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_PASSWORD_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.updatePassword(oldPassword, newPassword).then(
      (result) => {
        const message = 'Your password has been updated.';
        dispatch(alertActions.success(message));
        dispatch(push('/settings'));
        dispatch(success(result));
      },
      (error) => {
        const message =
          error.code === 'NotAuthorizedException'
            ? 'Sorry, your password is incorrect. Please try again.'
            : errorMessage(error);
        dispatch(alertActions.error(message));
        dispatch(failure(error.toString()));
      }
    );
  };
};

const updateMembership = (user, membershipLevel, stripePaymentMethodId, term) => {
  const request = () => ({ type: userConstants.UPDATE_MEMBERSHIP_REQUEST });
  const success = (result) => ({ type: userConstants.UPDATE_MEMBERSHIP_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.UPDATE_MEMBERSHIP_FAILURE, error });

  const oldStatus = user.userStatus;
  const updatedUser = { ...user, userStatus: membershipLevel };

  return (dispatch) => {
    dispatch(request());
    userService.updateMembership(updatedUser, stripePaymentMethodId, term).then(
      (result) => {
        // console.log('updateMembership success', result);
        if (result.status === 'incomplete') {
          const subscriptionStatus = result;
          dispatch(failure(subscriptionStatus));
        } else {
          dispatch(success(result));
          if (oldStatus === 'none') {
            const message = 'Your membership status has been set! Welcome!';
            dispatch(alertActions.success(message));
            dispatch(push('/how-it-works'));
          } else {
            const message = 'Your membership status has been updated!';
            dispatch(alertActions.success(message));
            dispatch(push('/settings'));
          }
        }
      },
      (error) => {
        // console.log('updateMembership error', error);
        dispatch(alertActions.error(errorMessage(error), { inline: true }));
        dispatch(failure(error.response));
      }
    );
  };
};

const _delete = (user) => {
  const request = () => ({ type: userConstants.DELETE_REQUEST });
  const success = (result) => ({ type: userConstants.DELETE_SUCCESS, result });
  const failure = (error) => ({ type: userConstants.DELETE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.delete(user).then(
      (result) => {
        userService.signout().then(() => {
          const message = 'Your account has been deleted.';
          dispatch(alertActions.success(message));
          dispatch(push('/'));
          dispatch(success(result));
        });
      },
      (error) => {
        const message = errorMessage(error);
        dispatch(alertActions.error(message));
        dispatch(failure(error.toString()));
      }
    );
  };
};

const userActions = {
  signup,
  confirmSignup,
  resendSignupCode,
  signin,
  signout,
  currentSession,
  forgotPasswordCode,
  forgotPasswordUpdate,
  resendForgotPasswordCode,
  get,
  update,
  updatePreferences,
  updateEmail,
  listSublicensees,
  updateSublicensees,
  updateInvitation,
  resendUpdateEmailCode,
  confirmUpdateEmail,
  challengePassword,
  updatePassword,
  updateMembership,
  delete: _delete,
};
export default userActions;
