import billingConstants from './constants';

const initialState = {
  isPurchasingOneTimeMembership: false,
  isGettingProducts: false,
  isGettingPaymentMethod: false,
  isUpdatingPaymentMethod: false,
  isGoingToBillingPortal: false,
  products: null,
  customer: null,
  paymentMethod: null,
  error: null,
};
const billingReducer = (state = initialState, action) => {
  // console.log('billingReducer', action);
  switch (action.type) {
    case billingConstants.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isGettingProducts: true,
        products: null,
        error: null,
      };
    case billingConstants.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isGettingProducts: false,
        products: action.result,
      };
    case billingConstants.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        isGettingProducts: false,
        error: action.error,
      };

    case billingConstants.PURCHASE_ONE_TIME_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isPurchasingOneTimeMembership: true,
        error: null,
      };
    case billingConstants.PURCHASE_ONE_TIME_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isPurchasingOneTimeMembership: false,
      };
    case billingConstants.PURCHASE_ONE_TIME_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isPurchasingOneTimeMembership: false,
        error: action.error,
      };
    case billingConstants.GET_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        isGettingPaymentMethod: true,
        paymentMethod: null,
        error: null,
      };
    case billingConstants.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isGettingPaymentMethod: false,
        paymentMethod: action.result,
      };
    case billingConstants.GET_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isGettingPaymentMethod: false,
        error: action.error,
      };
    case billingConstants.UPDATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        isUpdatingPaymentMethod: true,
        error: null,
      };
    case billingConstants.UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isUpdatingPaymentMethod: false,
        paymentMethod: action.result,
      };
    case billingConstants.UPDATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isUpdatingPaymentMethod: false,
        error: action.error,
      };
    case billingConstants.GET_CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        isGettingCustomerInfo: true,
        error: null,
      };
    case billingConstants.GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        customer: action.result,
        isGettingCustomerInfo: false,
      };
    case billingConstants.GET_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        isGettingCustomerInfo: false,
        error: action.error,
      };
    case billingConstants.GOTO_BILLING_PORTAL_REQUEST:
      return {
        ...state,
        isGoingToBillingPortal: true,
        error: null,
      };
    case billingConstants.GOTO_BILLING_PORTAL_SUCCESS:
      return {
        ...state,
        isGoingToBillingPortal: false,
      };
    case billingConstants.GOTO_BILLING_PORTAL_FAILURE:
      return {
        ...state,
        isGoingToBillingPortal: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default billingReducer;
