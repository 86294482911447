import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';

import userActions from '../../api/user/actions';
import useAppContext from '../../helpers/useAppContext';

import './Header.scss';

const Header = (props) => {
  const authentication = useSelector((state) => state.authentication);
  const { isSilentlySigningIn, isSignedIn } = authentication;

  const dispatch = useDispatch();

  const { isNavVisible } = useAppContext();

  const [navExpanded, setNavExpanded] = useState(false);
  const toggleNav = (expanded) => setNavExpanded(expanded);
  const openNav = () => setNavExpanded(true);
  const closeNav = () => setNavExpanded(false);

  const signout = (event) => {
    event.preventDefault();
    dispatch(userActions.signout());
  };

  return (
    <div className="Header">
      <>
        {isSilentlySigningIn && (
          <Navbar
            expand="sm"
            className="fixed fixed-top"
            id="navbar-top"
            onToggle={() => toggleNav()}
            expanded={navExpanded}
          >
            <Navbar.Brand>
              {isNavVisible && (
                <Link to="/" className="logo" onClick={() => closeNav()}>
                  Talk Sync
                </Link>
              )}
              {!isNavVisible && <div className="logo">Talk Sync</div>}
            </Navbar.Brand>
          </Navbar>
        )}
        {!isSilentlySigningIn && !isSignedIn && (
          <ScrollspyNav
            scrollTargetIds={['about', 'how-it-works', 'pricing', 'contact-us']}
            offset={60}
            activeNavClass="active"
          >
            <Navbar
              expand="sm"
              className="fixed fixed-top"
              id="navbar-top"
              onToggle={() => toggleNav()}
              expanded={navExpanded}
            >
              <Navbar.Brand>
                {isNavVisible && (
                  <Link to="/" className="logo" onClick={() => closeNav()}>
                    Talk Sync
                  </Link>
                )}
                {!isNavVisible && <div className="logo">Talk Sync</div>}
              </Navbar.Brand>
              {isNavVisible && <Navbar.Toggle className="primary" onClick={() => openNav()} />}
              {isNavVisible && (
                <Navbar.Collapse>
                  <Nav className="ml-auto justify-content-end" id="nav-top">
                    <LinkContainer onClick={() => closeNav()} to="/#about">
                      <Nav.Item as="a" id="about-out">
                        About
                      </Nav.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => closeNav()} to="/#how-it-works">
                      <Nav.Item as="a" id="how-it-works-out">
                        How it works
                      </Nav.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => closeNav()} to="/#pricing">
                      <Nav.Item as="a" id="pricing-out">
                        Pricing
                      </Nav.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => closeNav()} to="/#contact-us">
                      <Nav.Item as="a" id="contact-us-out">
                        Contact us
                      </Nav.Item>
                    </LinkContainer>

                    <LinkContainer onClick={() => closeNav()} to="/signin">
                      <Nav.Item as="button" id="sign-in-out" className="btn btn-outline-primary">
                        Sign in
                      </Nav.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => closeNav()} to="/signup">
                      <Nav.Item as="button" id="sign-up-out" className="btn btn-primary">
                        Sign up
                      </Nav.Item>
                    </LinkContainer>
                  </Nav>
                </Navbar.Collapse>
              )}
            </Navbar>
          </ScrollspyNav>
        )}
        {isSignedIn && (
          <Navbar
            expand="sm"
            className="fixed fixed-top"
            id="navbar-top"
            onToggle={() => toggleNav()}
            expanded={navExpanded}
          >
            <Navbar.Brand>
              {isNavVisible && (
                <Link to="/" className="logo" onClick={() => closeNav()}>
                  Talk Sync
                </Link>
              )}
              {!isNavVisible && <div className="logo">Talk Sync</div>}
            </Navbar.Brand>
            {isNavVisible && <Navbar.Toggle className="primary" onClick={() => openNav()} />}
            {isNavVisible && (
              <Navbar.Collapse>
                <Nav className="ml-auto justify-content-end" id="nav-top">
                  <LinkContainer onClick={() => closeNav()} to="/how-it-works">
                    <Nav.Item as="a" id="how-it-works-in">
                      How It Works
                    </Nav.Item>
                  </LinkContainer>
                  <LinkContainer onClick={() => closeNav()} to="/recordings">
                    <Nav.Item as="a" id="your-recordings-in">
                      Your recordings
                    </Nav.Item>
                  </LinkContainer>
                  <LinkContainer onClick={() => closeNav()} to="/settings">
                    <Nav.Item as="a" id="settings-in">
                      Settings
                    </Nav.Item>
                  </LinkContainer>

                  <LinkContainer onClick={signout} to="/signout">
                    <Nav.Item as="a" id="sign-out-in">
                      Sign out
                    </Nav.Item>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            )}
          </Navbar>
        )}
      </>
    </div>
  );
};

export default Header;
