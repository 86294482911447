import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import ChallengedRoute from './ChallengedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import Loader from '../components/page/Loader';

// public pages
const HomePage = lazy(() => retry(() => import('../containers/public/Home')));
const GetTheApp = lazy(() => retry(() => import('../containers/public/GetTheApp')));
const OpenTheApp = lazy(() => retry(() => import('../containers/public/OpenTheApp')));
const NotFoundPage = lazy(() => retry(() => import('../containers/common/NotFound')));
const TermsPage = lazy(() => retry(() => import('../containers/common/Terms')));
const SubscriptionTermsPage = lazy(() => retry(() => import('../containers/common/SubscriptionTerms')));
const PrivacyPage = lazy(() => retry(() => import('../containers/common/Privacy')));

// authentication
const SigninPage = lazy(() => retry(() => import('../containers/authentication/Signin')));
const SignupPage = lazy(() => retry(() => import('../containers/authentication/Signup')));
const SignupConfirmationPage = lazy(() => retry(() => import('../containers/authentication/SignupConfirmation')));
const ForgotPasswordPage = lazy(() => retry(() => import('../containers/authentication/ForgotPassword')));
const ForgotPasswordResetPage = lazy(() => retry(() => import('../containers/authentication/ForgotPasswordReset')));
const ChallengePasswordPage = lazy(() => retry(() => import('../containers/authentication/ChallengePassword')));

// private pages
const UserHowItWorksPage = lazy(() => retry(() => import('../containers/private/how-it-works/HowItWorks')));
const UserRecordingsPage = lazy(() => retry(() => import('../containers/private/recordings/Recordings')));

// settings pages
const UserSettingsPage = lazy(() => retry(() => import('../containers/private/user/Settings')));
const UserAcceptInvitationsPage = lazy(() => retry(() => import('../containers/private/user/AcceptInvitations')));
const UserEnterpriseSettingsPage = lazy(() => retry(() => import('../containers/private/user/EnterpriseSettings')));
const UserChangePasswordPage = lazy(() => retry(() => import('../containers/private/user/ChangePassword')));
const UserChangeEmailPage = lazy(() => retry(() => import('../containers/private/user/ChangeEmail')));
const UserChangeEmailConfirmationPage = lazy(() =>
  retry(() => import('../containers/private/user/ChangeEmailConfirmation'))
);
const UserDeleteAccountPage = lazy(() => retry(() => import('../containers/private/user/DeleteAccount')));
const ChooseMembershipPage = lazy(() => retry(() => import('../containers/private/user/ChooseMembership')));
const ChangeMembershipPage = lazy(() => retry(() => import('../containers/private/user/ChangeMembership')));
const PurchaseEnterpriseMembershipPage = lazy(() =>
  retry(() => import('../containers/private/user/PurchaseEnterpriseMembership'))
);
const PurchaseOneTimeMembershipPage = lazy(() =>
  retry(() => import('../containers/private/user/PurchaseOneTimeMembership'))
);
const ChangeBillingPage = lazy(() => retry(() => import('../containers/private/user/ChangeBilling')));
const BillingPortalPage = lazy(() => retry(() => import('../containers/private/user/BillingPortal')));

const retry = (fn, retriesLeft = 5, interval = 500) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/get-the-app" exact>
          <GetTheApp />
        </Route>
        <Route path="/c/:linkcode" exact showNav={false}>
          <OpenTheApp />
        </Route>
        <Route path="/terms" exact>
          <TermsPage />
        </Route>
        <Route path="/subscription-terms" exact>
          <SubscriptionTermsPage />
        </Route>
        <Route path="/privacy" exact>
          <PrivacyPage />
        </Route>

        <UnauthenticatedRoute path="/signup" exact>
          <SignupPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute path="/signup/confirmation" exact>
          <SignupConfirmationPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute path="/signin" exact>
          <SigninPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute path="/signin/forgot" exact>
          <ForgotPasswordPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute path="/signin/forgot/reset" exact>
          <ForgotPasswordResetPage />
        </UnauthenticatedRoute>

        <ChallengedRoute path="/signin/new-password" exact>
          <ChallengePasswordPage />
        </ChallengedRoute>

        <AuthenticatedRoute path="/how-it-works" exact>
          <UserHowItWorksPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/recordings" exact>
          <UserRecordingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings" exact>
          <UserSettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/accept-invitations" exact>
          <UserAcceptInvitationsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/account/delete" exact>
          <UserDeleteAccountPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/billing" exact>
          <ChangeBillingPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/billing-portal" exact>
          <BillingPortalPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/email" exact>
          <UserChangeEmailPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/email/confirmation" exact>
          <UserChangeEmailConfirmationPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/licenses" exact>
          <UserEnterpriseSettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/membership" exact>
          <ChangeMembershipPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/membership/enterprise" exact>
          <PurchaseEnterpriseMembershipPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings/password" exact>
          <UserChangePasswordPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/choose-membership" exact>
          <ChooseMembershipPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/purchase-membership" exact>
          <PurchaseOneTimeMembershipPage />
        </AuthenticatedRoute>

        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
