import React from 'react';
import { useSelector } from 'react-redux';

import { AlertDismissable } from './AlertDismissable';

export const AlarmAlert = ({ dismissable }) => {
  const authentication = useSelector((state) => state.authentication);
  const { isSignedIn } = authentication;
  const { user } = useSelector((state) => state.user);
  const hasMadeRecordings = user && user.recordingsCount > 0;

  return (
    isSignedIn &&
    hasMadeRecordings && (
      <AlertDismissable dismissable={dismissable} preference={'showAlarmAlert'}>
        <p>
          There is a currently a known issue where an alarm going off during recording will cause the recording to be
          deleted and unrecoverable.
        </p>
        <p>
          While we work on the issue, please <strong>ensure that your interview subject has disabled all alarms</strong>{' '}
          on their phone before starting recording.
        </p>
      </AlertDismissable>
    )
  );
};
