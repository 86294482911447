import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const isDev = process.env.NODE_ENV === 'development';

export function initSentry() {
  if (isDev) {
    return;
  }

  Sentry.init({
    dsn: 'https://909eb068cfde493ba8bead26e2bf926c@o394606.ingest.sentry.io/5244947',
    release: 'talk-sync-client@' + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
  });
}

export function logError(error) {
  if (isDev) {
    return;
  }

  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}
