const billingConstants = {
  GET_PRODUCTS_REQUEST: 'USERS_GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'USERS_GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'USERS_GET_PRODUCTS_FAILURE',

  PURCHASE_ONE_TIME_MEMBERSHIP_REQUEST: 'USERS_PURCHASE_ONE_TIME_MEMBERSHIP_REQUEST',
  PURCHASE_ONE_TIME_MEMBERSHIP_SUCCESS: 'USERS_PURCHASE_ONE_TIME_MEMBERSHIP_SUCCESS',
  PURCHASE_ONE_TIME_MEMBERSHIP_FAILURE: 'USERS_PURCHASE_ONE_TIME_MEMBERSHIP_FAILURE',

  GET_PAYMENT_METHOD_REQUEST: 'USERS_GET_PAYMENT_METHOD_REQUEST',
  GET_PAYMENT_METHOD_SUCCESS: 'USERS_GET_PAYMENT_METHOD_SUCCESS',
  GET_PAYMENT_METHOD_FAILURE: 'USERS_GET_PAYMENT_METHOD_FAILURE',

  UPDATE_PAYMENT_METHOD_REQUEST: 'USERS_UPDATE_PAYMENT_METHOD_REQUEST',
  UPDATE_PAYMENT_METHOD_SUCCESS: 'USERS_UPDATE_PAYMENT_METHOD_SUCCESS',
  UPDATE_PAYMENT_METHOD_FAILURE: 'USERS_UPDATE_PAYMENT_METHOD_FAILURE',

  GET_CUSTOMER_INFO_REQUEST: 'USERS_GET_CUSTOMER_INFO_REQUEST',
  GET_CUSTOMER_INFO_SUCCESS: 'USERS_GET_CUSTOMER_INFO_SUCCESS',
  GET_CUSTOMER_INFO_FAILURE: 'USERS_GET_CUSTOMER_INFO_FAILURE',

  GOTO_BILLING_PORTAL_REQUEST: 'USERS_GOTO_BILLING_PORTAL_REQUEST',
  GOTO_BILLING_PORTAL_SUCCESS: 'USERS_GOTO_BILLING_PORTAL_SUCCESS',
  GOTO_BILLING_PORTAL_FAILURE: 'USERS_GOTO_BILLING_PORTAL_FAILURE',
};
export default billingConstants;
