const linkcodeConstants = {
  CREATE_REQUEST: 'LINKCODE_CREATE_REQUEST',
  CREATE_SUCCESS: 'LINKCODE_CREATE_SUCCESS',
  CREATE_FAILURE: 'LINKCODE_CREATE_FAILURE',

  LIST_REQUEST: 'LINKCODE_LIST_REQUEST',
  LIST_SUCCESS: 'LINKCODE_LIST_SUCCESS',
  LIST_FAILURE: 'LINKCODE_LIST_FAILURE',

  GET_REQUEST: 'LINKCODE_GET_REQUEST',
  GET_SUCCESS: 'LINKCODE_GET_SUCCESS',
  GET_FAILURE: 'LINKCODE_GET_FAILURE',

  CHECK_REQUEST: 'LINKCODE_CHECK_REQUEST',
  CHECK_SUCCESS: 'LINKCODE_CHECK_SUCCESS',
  CHECK_FAILURE: 'LINKCODE_CHECK_FAILURE',

  TEXT_REQUEST: 'LINKCODE_TEXT_REQUEST',
  TEXT_SUCCESS: 'LINKCODE_TEXT_SUCCESS',
  TEXT_FAILURE: 'LINKCODE_TEXT_FAILURE',

  UPDATE_REQUEST: 'LINKCODE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'LINKCODE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'LINKCODE_UPDATE_FAILURE',

  DELETE_REQUEST: 'LINKCODE_DELETE_REQUEST',
  DELETE_SUCCESS: 'LINKCODE_DELETE_SUCCESS',
  DELETE_FAILURE: 'LINKCODE_DELETE_FAILURE',
};

export default linkcodeConstants;
