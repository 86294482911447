import userConstants from './constants';

const initialState = {
  isLoading: false,
  isUpdating: false,
  isUpdatingMembership: false,
  isDeleting: false,
  isListingSublicensees: false,
  sublicensees: null,
  isUpdatingInvitation: false,
  isDecliningInvitation: false,
  user: null,
  membershipType: null,
  error: null,
};

const setMembershipType = (user) => {
  if (user.userStatus === 'pro' && user.licenses && user.licenses > 0) {
    return 'pro_enterprise';
  } else if (user.userStatus === 'pro' && user.parentLicensee && user.parentLicensee.user) {
    return 'pro_sublicensee';
  } else if (user.userStatus === 'pro' && !user.stripeSubscriptionId && user.userStatusExpiresAt) {
    return 'pro_expiring';
  } else if (user.userStatus === 'pro' && !user.stripeSubscriptionId && !user.userStatusExpiresAt) {
    return 'pro_lifetime';
  } else if (user.userStatus === 'pro' && user.stripeSubscriptionId) {
    return 'pro_subscribing';
  } else {
    return user.userStatus;
  }
};

const userReducer = (state = initialState, action) => {
  // console.log('userReducer', action);
  switch (action.type) {
    case userConstants.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.result,
        membershipType: setMembershipType(action.result),
      };
    case userConstants.GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        user: { ...state.user, ...action.result },
        membershipType: setMembershipType({ ...state.user, ...action.result }),
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case userConstants.UPDATE_PREFERENCE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case userConstants.UPDATE_PREFERENCE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        user: { ...state.user, ...action.result },
        membershipType: setMembershipType({ ...state.user, ...action.result }),
      };
    case userConstants.UPDATE_PREFERENCE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case userConstants.UPDATE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isUpdatingMembership: true,
        error: null,
      };
    case userConstants.UPDATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isUpdatingMembership: false,
        user: { ...state.user, ...action.result },
        membershipType: setMembershipType({ ...state.user, ...action.result }),
      };
    case userConstants.UPDATE_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isUpdatingMembership: false,
        error: action.error,
      };
    case userConstants.LIST_SUBLICENSEES_REQUEST:
      return {
        ...state,
        isListingSublicensees: true,
        error: null,
      };
    case userConstants.LIST_SUBLICENSEES_SUCCESS:
      return {
        ...state,
        isListingSublicensees: false,
        sublicensees: action.result,
      };
    case userConstants.LIST_SUBLICENSEES_FAILURE:
      return {
        ...state,
        isListingSublicensees: false,
        error: action.error,
      };
    case userConstants.UPDATE_SUBLICENSEES_REQUEST:
      return {
        ...state,
        isUpdatingSublicensees: true,
        error: null,
      };
    case userConstants.UPDATE_SUBLICENSEES_SUCCESS:
      return {
        ...state,
        isUpdatingSublicensees: false,
        user: { ...state.user, ...action.result },
        membershipType: setMembershipType({ ...state.user, ...action.result }),
        sublicensees: null,
      };
    case userConstants.UPDATE_SUBLICENSEES_FAILURE:
      return {
        ...state,
        isUpdatingSublicensees: false,
        error: action.error,
      };
    case userConstants.UPDATE_INVITATION_REQUEST:
      return {
        ...state,
        isUpdatingInvitation: true,
        error: null,
      };
    case userConstants.UPDATE_INVITATION_SUCCESS:
      return {
        ...state,
        isUpdatingInvitation: false,
        user: { ...state.user, ...action.result },
        membershipType: setMembershipType({ ...state.user, ...action.result }),
        sublicensees: null,
      };
    case userConstants.UPDATE_INVITATION_FAILURE:
      return {
        ...state,
        isUpdatingInvitation: false,
        error: action.error,
      };
    case userConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case userConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        user: null,
        membershipType: null,
      };
    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default userReducer;
