import { API, Auth } from 'aws-amplify';
import * as helpers from '../../helpers/helper-functions';

// all of following  are handled by cognito Auth

const signup = (email, password) => {
  return Auth.signUp(email, password);
};

const confirmSignup = (email, code) => {
  return Auth.confirmSignUp(email, code);
};

const resendSignupCode = (email) => {
  return Auth.resendSignUp(email);
};

const signin = (email, password) => {
  return Auth.signIn(email, password);
};

const signout = () => {
  return Auth.signOut();
};

const currentSession = () => {
  return Auth.currentSession();
};

const forgotPasswordCode = (username) => {
  return Auth.forgotPassword(username);
};

const resendForgotPasswordCode = (email) => {
  return Auth.forgotPassword(email);
};

const forgotPasswordUpdate = (username, code, password) => {
  return Auth.forgotPasswordSubmit(username, code, password);
};

const updateEmail = async (email) => {
  const currentUser = Auth.user || (await Auth.currentAuthenticatedUser());
  return Auth.updateUserAttributes(currentUser, { email });
};

const resendUpdateEmailCode = (email) => {
  return Auth.verifyCurrentUserAttribute('email');
};

const confirmUpdateEmail = (code) => {
  return Auth.verifyCurrentUserAttributeSubmit('email', code);
};

const challengePassword = async (cognitoUser, newPassword) => {
  return Auth.completeNewPassword(cognitoUser, newPassword, []).then(
    (result) => new Promise((resolve, reject) => resolve(result)),
    (error) => new Promise((resolve, reject) => reject(error))
  );
};

const updatePassword = async (oldPassword, newPassword) => {
  const currentUser = Auth.user || (await Auth.currentAuthenticatedUser());
  return Auth.changePassword(currentUser, oldPassword, newPassword);
};

const _delete = async () => {
  const currentUser = Auth.user || (await Auth.currentAuthenticatedUser());
  await API.del('users', '/users/me');
  return currentUser.deleteUser();
};

// the following are handled by api udpates to users table
const get = async () => {
  return API.get('users', '/users/me');
};

const update = async (attributes) => {
  const request = { body: attributes };
  return API.put('users', '/users/me', request);
};

const updateMembership = async (user, stripePaymentMethodId, term) => {
  const request = { body: { user, stripePaymentMethodId, term } };
  return API.put('users', '/users/me/membership', request);
};

const listSublicensees = async (conditions) => {
  const queryString = conditions ? `?${helpers.objectToQueryString(conditions)}` : '';
  return API.get('users', '/users' + queryString);
};

const updateSublicensees = async (attributes) => {
  const request = { body: attributes };
  return API.put('users', '/users/me/sublicensees', request);
};

const updateInvitation = async (userId, accept) => {
  const request = { body: { accept } };
  return API.put('users', `/users/me/invitations/${userId}`, request);
};

const userService = {
  signup,
  confirmSignup,
  resendSignupCode,
  signin,
  signout,
  currentSession,
  forgotPasswordCode,
  resendForgotPasswordCode,
  forgotPasswordUpdate,
  resendUpdateEmailCode,
  updateEmail,
  confirmUpdateEmail,
  challengePassword,
  updatePassword,
  updateMembership,
  listSublicensees,
  updateSublicensees,
  updateInvitation,
  get,
  update,
  delete: _delete,
};
export default userService;
