import React from 'react';
import { Link } from 'react-router-dom';
import useAppContext from '../../helpers/useAppContext';

import './Footer.scss';

const Footer = () => {
  const { isNavVisible } = useAppContext();

  return (
    <div className="Footer mt-auto py-3">
      <div className="row">
        <div className="col-7 d-flex flex-row">
          <img src="/assets/images/home-ws-star@3x.png" width="25" height="25" alt="" />
          <div>
            An app by Western Sound. <a href="https://western-sound.com">Learn more about our podcasts.</a>
          </div>
        </div>
        <div className="col-5">
          <div className="row text-right">
            <div className="col">© {new Date().getFullYear()} Western Sound, Inc.</div>
          </div>
          {isNavVisible && (
            <>
              <div className="row">
                <div className="col text-right">
                  <Link to="/privacy" id="privacy">
                    Privacy policy
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col text-right">
                  <Link to="/terms" id="terms">
                    Terms of service
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col text-right">
                  <Link to="/subscription-terms" id="subscription-terms">
                    Subscription terms
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
