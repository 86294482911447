// export const removeEmpty = obj => {
//   Object.entries(obj).forEach(([key, val])  => (
//     (val && typeof val === 'object') && removeEmpty(val)) ||
//     ((val === null || val === "") && delete obj[key])
//   );
//   return obj;
// };

// export const permalink = project => {
//   const permalink = (typeof(project.slug) !== 'undefined' && project.slug.length > 0) ? project.slug : project.projectId;
//   return permalink;
// }

export const monthsInSeconds = (seconds) => Math.floor(seconds / (30 * 60 * 60 * 24));
export const daysInSeconds = (seconds) => Math.floor(seconds / (60 * 60 * 24));
export const hoursInSeconds = (seconds) => Math.floor(seconds / (60 * 60));
export const minutesInSeconds = (seconds) => Math.floor(seconds / 60);
export const secondsInSeconds = (seconds) => Math.floor(seconds % 60);

export const formatTime = (totalSeconds) => {
  const days = daysInSeconds(totalSeconds);
  const hours = hoursInSeconds(totalSeconds) % 24;
  const minutes = minutesInSeconds(totalSeconds) % 60;
  const seconds = secondsInSeconds(totalSeconds);
  const secondsString = ':' + seconds.toString().padStart(2, '0');
  const minutesString =
    days || hours || minutes ? (days || hours ? ':' + minutes.toString().padStart(2, '0') : minutes.toString()) : '0';
  const hoursString = days || hours ? (days ? ':' + hours.toString().padStart(2, '0') : hours.toString()) : '';
  const daysString = days ? days.toString() : '';
  return daysString + hoursString + minutesString + secondsString;
};

export const formatBytes = (bytes) => {
  const kb = Math.round(bytes / 1024);
  if (kb < 1024) return `${kb} KB`;
  const mb = kb / 1024;
  if (mb < 1024) return `${mb.toFixed(2)} MB`;
  const gb = mb / 1024;
  return `${gb.toFixed(2)} GB`;
};

export const parseError = (error) => {
  if (error.response && error.response.data && error.response.data.error) return error.response.data.error;
  return { message: error.toString() };
};

export const differencInMonths = (date1, date2) => {
  if (date1.constructor.name !== 'Date' || date2.constructor.name !== 'Date') return NaN;
  const difference = date1 - date2;
  const differencInMonths = monthsInSeconds(difference / 1000);
  return differencInMonths;
};

export const objectToQueryString = (obj) =>
  Object.keys(obj)
    .map((key) => {
      const encodedKey = encodeURIComponent(key);
      const valueToEncode = typeof obj[key] === 'object' ? JSON.stringify(obj[key]) : obj[key];
      const encodedValue = encodeURIComponent(valueToEncode);
      return `${encodedKey}=${encodedValue}`;
    })
    .join('&');
