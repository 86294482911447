import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './AlertDismissable.scss';
import userActions from '../../api/user/actions';

export const AlertDismissable = ({ preference = null, dismissable = false, children }) => {
  const authentication = useSelector((state) => state.authentication);
  const { isSignedIn } = authentication;
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const showAlert =
    !dismissable ||
    !preference ||
    (user && user.preferences && (user.preferences[preference] === undefined || user.preferences[preference]));

  const handleCloseClicked = (_event) => {
    if (isSignedIn && preference) {
      dispatch(userActions.updatePreferences(user, { [preference]: false }));
    }
  };

  return showAlert && children ? (
    <div className="AlertDismissable alert alert-danger alert-dismissible show" role="alert">
      <FontAwesomeIcon
        style={{ width: 50, height: 50, float: 'left', margin: '0 1rem' }}
        icon={faExclamationTriangle}
      />
      {children}
      {dismissable && (
        <button type="button" className="close" aria-label="Close" onMouseUp={handleCloseClicked}>
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  ) : null;
};
