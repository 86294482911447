import alertConstants from './constants';

const initialState = null;

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        inline: action.options ? action.options.inline : false,
        errorType: action.options ? action.options.errorType : null,
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        inline: action.options ? action.options.inline : false,
        errorType: action.options ? action.options.errorType : null,
        message: action.message,
      };
    case alertConstants.CLEAR:
      return null;
    default:
      return state;
  }
};

export default alertReducer;
