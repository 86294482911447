import recordingConstants from './constants';

const initialState = {
  isListingRecordings: false,
  isGettingRecording: false,
  isUpdatingRecording: false,
  isDeleteingRecording: false,
  items: null,
  item: null,
  error: null,
};

const recordingReducer = (state = initialState, action) => {
  switch (action.type) {
    case recordingConstants.LIST_REQUEST:
      return {
        ...state,
        isListingRecordings: true,
        error: null,
      };
    case recordingConstants.LIST_SUCCESS:
      return {
        ...state,
        isListingRecordings: false,
        items: action.result,
      };
    case recordingConstants.LIST_FAILURE:
      return {
        ...state,
        isListingRecordings: false,
        items: null,
        error: action.error,
      };
    case recordingConstants.GET_REQUEST:
      return {
        ...state,
        isGettingRecording: true,
        error: null,
      };
    case recordingConstants.GET_SUCCESS:
      return {
        ...state,
        isGettingRecording: false,
        item: action.result,
      };
    case recordingConstants.GET_FAILURE:
      return {
        ...state,
        isGettingRecording: false,
        item: null,
        error: action.error,
      };
    case recordingConstants.DOWNLOAD_REQUEST:
      return {
        ...state,
        isDownloadingRecording: true,
        error: null,
      };
    case recordingConstants.DOWNLOAD_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => (item.recordingId === action.result.recordingId ? action.result : item)),
        isDownloadingRecording: false,
      };
    case recordingConstants.DOWNLOAD_FAILURE:
      return {
        ...state,
        isDownloadingRecording: false,
        error: action.error,
      };
    case recordingConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdatingRecording: true,
        error: null,
      };
    case recordingConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingRecording: false,
        items: state.items.map((item) => (item.recordingId === action.result.recordingId ? action.result : item)),
        item: action.result,
      };
    case recordingConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdatingRecording: false,
        error: action.error,
      };
    case recordingConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleteingRecording: true,
        error: null,
      };
    case recordingConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleteingRecording: false,
        items: state.items.filter((item) => item.recordingId !== action.result.recordingId),
        item: null,
      };
    case recordingConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleteingRecording: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default recordingReducer;
