import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import alertActions from '../../api/alert/actions';

import './Alert.scss';
import config from '../../config';

const Alert = () => {
  const alert = useSelector((state) => state.alert);
  // const alert = { type: 'alert-danger', message: 'alert' };
  const dispatch = useDispatch();

  const clearAlert = useCallback(() => {
    if (alert != null) {
      dispatch(alertActions.clear());
    }
  }, [alert, dispatch]);

  useEffect(() => {
    if (alert != null) {
      const clearAlertTimeout = setTimeout(() => {
        clearAlert();
      }, config.site.ALERT_FADEOUT);
      return () => clearTimeout(clearAlertTimeout);
    }
  }, [alert, clearAlert]);

  const handleAlertClick = (event) => {
    event.preventDefault();
    clearAlert();
  };

  return (
    <>
      {alert && !alert.inline && (
        <div className="Alert">
          <div className="row">
            <div className="col-12">
              <div className={`row alert ${alert.type}`}>
                <div className="col-10 col-sm-11">
                  {alert.message}
                  {alert.errorType === 'UpgradeError' && (
                    <p>
                      <Link to="/settings/membership">Click here</Link> to upgrade your membership.
                    </p>
                  )}
                </div>
                <div className="col text-right">
                  <Link to="!#" className="close-box" onClick={handleAlertClick}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
