import contactConstants from './constants';

const initialState = {
  isContacting: false,
  isContactSuccess: false,
  isContactError: false,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactConstants.CONTACT_REQUEST:
      return {
        isContacting: true,
        isContactSuccess: false,
        isContactError: false,
        user: null,
      };
    case contactConstants.CONTACT_SUCCESS:
      return {
        isContacting: false,
        isContactSuccess: true,
      };
    case contactConstants.CONTACT_FAILURE:
      return {
        isContacting: false,
        isContactError: true,
        message: action.message,
      };
    case contactConstants.CONTACT_RESET:
      return {
        isContacting: false,
        isContactSuccess: false,
        message: null,
      };
    default:
      return state;
  }
};

export default contactReducer;
