import { useContext } from 'react';
import { AppContext } from './AppContext';

const useAppContext = () => {
  const [state, setState] = useContext(AppContext);

  function showNav(shouldShow = true) {
    setState((state) => ({ ...state, showNav: shouldShow }));
  }

  return {
    showNav,
    isNavVisible: state.showNav,
  };
};

export default useAppContext;
